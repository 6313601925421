import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  pageTitle: 'Senior Full Stack Developer PHP',
  introTitle: 'Senior Full Stack Developer PHP (m/f/d)',
  introInfo: '<p>freshcells systems engineering GmbH is a software service provider based in Düsseldorf with a dynamic team of over 40 creative experts from various fields.</p><p>We focus on authenticity, individual talent development and open communication. We build on respect, trust and responsibility. As an innovation-driven company, we invest in new ideas. We know that the best results are achieved as a team. We make mistakes, but we learn from them. We are open and courageous for new paths.</p><p>Join us on our exciting journey in what is probably the most important and forward-looking area - software development - as a Senior Full Stack Developer PHP (m/f/d)!</p>',
  responsibilitiesTitle: 'Your Responsibilities',
  responsibility1: 'Development of modern frontend applications based on ReactJS, Next.js and TypeScript',
  responsibility2: 'Development of APIs based on GraphQL',
  responsibility3: 'Development of modern backend applications based on Node.js and PHP Symfony',
  responsibility4: 'Design and implementation of new features for sophisticated software solutions in cooperation with project management, design, and development departments',
  responsibility5: 'Writing and maintaining our automated test suites',
  responsibility6: 'Participation in continuous improvement processes',
  qualificationsTitle: 'Your Qualifications',
  qualification1: 'Very good knowledge of modern JavaScript (ES6 and above) and/or TypeScript',
  qualification2: 'Very good knowledge with Next.js',
  qualification3: 'Extensive knowledge of frontend frameworks and libraries such as ReactJS',
  qualification4: 'Good knowledge of modern PHP (v8 and above)',
  qualification5: 'Good knowledge of PHP frameworks and libraries such as Symfony and/or Laravel',
  qualification6: 'SQL experience with relational databases',
  qualification7: 'Good understanding of established and evolving Web APIs',
  qualification8: 'Competences in consuming and creating web services based on REST and especially GraphQL',
  qualification9: 'Know-how in performance optimization',
  qualification10: 'Basic knowledge of HTML and CSS is helpful',
  qualification11: 'Experience with agile development in a team',
  qualification12: 'Hands-on experience with automated software testing',
  qualification13: 'Basic knowledge in API security',
  qualification14: 'Fluent in English',
  nicetohaveTitle: 'Nice to have',
  nicetohave1: 'Experience with containerization technologies such as Docker and container orchestration tools like, i.a., Kubernetes, OpenShift, or Docker Swarm',
  nicetohave2: 'Knowledge of designing and adapting software according to the twelve-factor methodology',
  nicetohave3: 'Knowledge of continuous integration via tools like bitbucket or Jenkins',
  nicetohave4: 'Experience with Object-relational mapping, e.g. using Doctrine in PHP',
  nicetohave5: 'Experience with headless CMS such as Strapi',
  nicetohave6: 'Experience with PostgreSQL',
  nicetohave7: 'Experience with Apollo Federation',
  nicetohave8: 'Experience with the integration of Analytics tools like GA4, GTM, amplitude and/or Matomo'
})
